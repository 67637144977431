.rtlResponsive{
    direction: rtl;
}

.gu-language .responsive-lineHeight{
    line-height: 20px !important
}

.helpGuide{
    cursor: pointer;
}

.pa-language{
    font-family: Noto-sans-gurmukhi !important;
}

.si-language{
    font-family: Noto-sans-sinhala !important;
}
